
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  getClassificationScores,
  getNormalScores,
  getBalancedEquationScores
} from '../api';

import { navigation } from '../data';
import SubPagesLayout from '../../UI/layout/subPages';
import { LeaderboardOverall } from '../../UI/instructor/leaderboard/overall';

import '../styles.css';

export default function DecisionInsightsOverall() {
  const navigate = useNavigate();

  const [filterBy, setFilterBy] = useState('accuracy');
  const [data, setData] = useState(null);

  const { data: classificationScores } = useQuery({
    queryKey: ['classificationScoresOverall'],
    queryFn: async () => getClassificationScores()
  });

  const { data: normalScores } = useQuery({
    queryKey: ['normalScoresOverall'],
    queryFn: async () => getNormalScores()
  });

  const { data: balancedEquationScores } = useQuery({
    queryKey: ['equationScoresOverall'],
    queryFn: async () => getBalancedEquationScores()
  });

  useEffect(() => {
    if(classificationScores && normalScores && balancedEquationScores) {
      formatLeaderboardData();
    }
  }, [classificationScores, normalScores, balancedEquationScores, filterBy]);

  const calculateAverage = (data, param) => {
    if (!data || data.length === 0) return 0;
  
    const uniqueSessions = new Set(data.map(record => record.game_session));

    const total = data.reduce((sum, record) => sum + record[param], 0);

    const divider = param === 'total_accuracy' ? data.length : uniqueSessions.size;
    return total / divider;
  };

  const formatLeaderboardData = () => {    
    let wholeLeaderboardData = [];
  
    const classificationScore = filterBy === 'accuracy' ? calculateAverage(classificationScores, 'total_accuracy') : calculateAverage(classificationScores, 'total_points');
    const classificationFormattedScore = filterBy === 'accuracy' ? (classificationScore * 100).toFixed(2) + '%' : classificationScore;
    
    const normalScore = filterBy === 'accuracy' ? calculateAverage(normalScores, 'total_accuracy') : calculateAverage(normalScores, 'total_points');
    const normalFormattedScore = filterBy === 'accuracy' ? (normalScore * 100).toFixed(2) + '%' : normalScore;
    
    const balancedEquationScore = filterBy === 'accuracy' ? calculateAverage(balancedEquationScores, 'total_accuracy') : calculateAverage(balancedEquationScores, 'total_points');
    const balancedEquationFormattedScore = filterBy === 'accuracy' ? (balancedEquationScore * 100).toFixed(2) + '%' : balancedEquationScore;
    
    wholeLeaderboardData = [
      {
        name: 'Classification',
        score: classificationScore,
        formattedScore: classificationFormattedScore,
        backgroundColor: 'rgba(85, 180, 188, 0.8)'
      },
      {
        name: 'Normal Balances',
        score: normalScore,
        formattedScore: normalFormattedScore,
        backgroundColor: '#FDD38A'
      },
      {
        name: 'Double Entry',
        score: balancedEquationScore,
        formattedScore: balancedEquationFormattedScore,
        backgroundColor: 'rgba(169, 141, 246, 0.8)'
      },
    ];

    setData(wholeLeaderboardData);
  }

  const handleFilterClick = (selectedFilterBy) => {
    setFilterBy(selectedFilterBy);
  }

  const maxScore = data ? data.reduce((max, item) => (item.score > max ? item.score : max), -Infinity) : 0;

  const handleNextButtonClick = () => {
    navigate('/insights/decision-insights/classifying-normal' );
  }

  return (
    <SubPagesLayout
      navigation={navigation}
      contentClassName="decision-insights-content-container"
      onSecondButtonClick={handleNextButtonClick}
    >
      <h1>Insights | Overall</h1>
      <h2>Overall</h2>
      <p>The chart below is an indicator of how well you understand accounting basics.</p>
      <LeaderboardOverall
        title="Performance"
        data={data}
        filterBy={filterBy}
        onFilterByClick={handleFilterClick}
        maxScore={maxScore}
      />
      <p><span className="red"><b>Note:</b></span> The chart will continuously update until the deadline has passed on all assignments.</p>
    </SubPagesLayout>
  );
}

export const navigation = [
  {
    title: 'Assignment',
    url: '/instructor/settings/assignments'
  }
];

export const gameCreateItemsQuestionsColumns = [
  'Assets',
  'Liabilities',
  'Equity',
  'Revenues',
  'Expenses'
];

export const gameCreateItemsOptionsClassification = [
  'Balance Sheet',
  'Income Statement'
];

export const gameCreateItemsOptionsNormal = [
  'Debit',
  'Credit'
];

export const scenarioLevelsDescription = {
  'Account Classification': [
    'The Elements of the Financial Statements: At this level, the 5 Elements of financial statements will fall one by one.: You have to sort each falling Element to the correct financial statement.',
    'The Accounts of the Financial Statements: At this level, several Accounts of financial statements will fall one by one.: You have to sort each falling Account to the correct Elements of the financial statement.'
  ],
  'Normal Balance': [
    'The Elements of the Financial Statements: At this level, the 5 Elements of financial statements will fall one by one.: You have to sort each falling Element to its correct Normal Balance.',
    'The Accounts of the Financial Statements: At this level, several Accounts of financial statements will fall one by one.: You have to sort each falling Account to its correct Normal Balance.',
  ],
  'Balanced Equation': [
    'Elements Impacted: At this level, you are going to review a series of transactions and have to decide how each transaction impacts the Balance Sheet Elements.: In Level I, you have to decide if the transaction increases or decreases Assets, Liabilities, and Equity.',
    'Accounts Impacted: In Level 2, you\'ll need to make an additional decision. You’ll need to determine which accounts will be impacted by each transaction.',
    'Debits & Credits: In this level, you\'ll need to make a third decision. You’ll need to determine which account should be debited and credited for each transaction.'
  ]
}